.bannerContainer{
    min-height: 150px;
    margin: 2rem auto 0 auto;
    text-align: center;
    cursor: pointer;
}
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}