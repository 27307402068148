#custom-input::placeholder {
  color: #ccc;
}

@media only screen and (max-width: 1500px) {
  .logoSection {
    display: none;
  }

  .mainDiv {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  input {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    height: 3rem;
  }

  .mainDiv {
    width: 95%;
  }
}
