.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}

.mainDiv {
  width: 30%;
}

.logoSection {
  display: block;
}

.labelInput {
  display: block;
}

.card-container {
  display: none;
}

@media only screen and (max-width: 1500px) {
  .logoSection {
    display: none;
  }

  .mainDiv {
    width: 50%;
  }
}

@media only screen and (max-width: 1024px) {
  .mainContainer {
    margin: 1rem;
  }

  .mainDiv {
    width: 85%;
  }
}

@media only screen and (max-width: 700px) {
  .mainContainer {
    margin: 1rem;
  }
  .mainDiv {
    width: 95%;
  }

  .table-container {
    display: none;
  }
  .card-container {
    display: block;
  }
}
