.headerContainer {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.logoContainer {
    display: none;
    width: 90px;
}

@media only screen and (max-width: 1500px) {

    .logoContainer {
        display: block;
        width: 90px;
    }
}