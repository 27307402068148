.card-container {
  direction: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  color: #676e77;
}

.card-container > div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.card-container .amount-container {
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
}
